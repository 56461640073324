import React from 'react';
import gsap from 'gsap';
import { AnimatePresence } from 'framer-motion';

export const wrapPageElement = ({ element }) => (
  <>
    <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
  </>
);

export const onInitialClientRender = () => {
  if (document.querySelector('#loading-screen')) {
    const loadingTl = gsap.timeline();
    const loadingScreen = document.querySelector('#loading-screen');
    const loadingBg1 = loadingScreen.querySelector('.loading-bg-1');
    const loadingBg2 = loadingScreen.querySelector('.loading-bg-2');
    const loadingLogo = loadingScreen.querySelector('svg');

    loadingTl.set(loadingScreen, {
      opacity: 1,
      visibility: 'visible',
    });
    loadingTl.to(
      [loadingBg2, loadingBg1],
      {
        duration: 1,
        scale: 4,
        ease: 'back.in(2.4)',
        stagger: 0.15,
      },
      '+=0.6'
    );
    loadingTl.to(
      loadingLogo,
      {
        duration: 0.8,
        scale: 2,
        ease: 'power3.out',
      },
      '-=0.45'
    );
    loadingTl.to(
      loadingScreen,
      {
        duration: 0.8,
        opacity: 0,
        ease: 'power3.out',
      },
      '-=0.6'
    );
    loadingTl.to(loadingScreen, {
      duration: 0,
      visibility: 'hidden',
    });
  }

  // Store in window object
  window.currentState = {
    category: 'all',
    location: 'all',
    fullTime: true,
    contract: true,
    onsite: true,
    hybrid: true,
    remote: true,
  };
};
